// --------------------------------------------------
//
// Fonts
//
// --------------------------------------------------

// Font Stacks

$font-url-google		:	'https://fonts.googleapis.com/css?family=ABeeZee';

$font-family-body		:		'ABeeZee', sans-serif;
$headings-font-family  	:		'ABeeZee', sans-serif;
 
//==== Fonts

$base__font-size		:		17px;
$base__line-height		:		30px;

$h1-line-height			:		44px;


body {
	font-family: $font-family-body;
	line-height: $base__line-height;
}
a {
	color: $tertiary;
	text-decoration: underline;
}

.navbar-custom .navbar-nav .nav-link {
	color: $secondary;
	font-size:  $base__font-size*1.2;
	text-decoration: none;
	}

.navbar-custom .navbar-nav .nav-link:hover {
	color: $tertiary-dark;
	}

h1 {
	color: $primary;
	line-height: $base__line-height*2;
}

h2 {
	color: $secondary;
	line-height: $base__line-height*2;
}

h1, h2 {

	@include media-breakpoint-down(md) { 
		font-size: $base__font-size*1.4;
		line-height: $base__line-height;
		 }
}



.centeredtext h2 {
	color: $gray-800;
}

.tertiary-dark {
	color: $tertiary-dark;
}
