
// Hamburger
// ==================================================
.hamburger {
  padding: $hamburger-padding-y $hamburger-padding-x;
  display: inline-block;
  cursor: pointer;

  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  // Normalize (<button>)
  font: inherit;
	color: $primary;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover {
    @if $hamburger-hover-use-filter == true {
      filter: $hamburger-hover-filter;
    }
    @else {
      opacity: $hamburger-hover-opacity;
    }
  }

  &.is-active {
    &:hover {
      @if $hamburger-hover-use-filter == true {
        filter: $hamburger-active-hover-filter;
      }
      @else {
        opacity: $hamburger-active-hover-opacity;
      }
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: $primary;
    }
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-layer-height / -2;

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $primary;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}

.menu-text {

	font-size: $base__font-size*2.2;
}


body {
  // background-color: $gray-800;
  // background-image: url(/../../assets/brickwall.png);
  background-image: url(https://amc-build.co.uk/assets/brickwall.png);
}

.navbar-custom .navbar-toggler {
	border: $tertiary-dark;
	color: $tertiary-dark;
}

.container {
	background-color: white;
}

.image-container {
  position: relative;
  text-align: center;
}

/* Centered text */
.centeredtext {
	position: absolute;
	top: 10%;
	left: 0%;
	right: 0%;
	/* default fallback */
	background: rgb(255, 255, 255) transparent;
	/* nice browsers */
	background: rgba(255, 255, 255, 0.65);
	padding: 8px;
}


.ecobuilders {
	background-color: $primary;
	padding: 40px;
	color: $tertiary-dark;
}

.freeconsultation {
	background-color: $gray-200;
	padding: 40px;
}

.btn-primary {
	color: $tertiary-dark;
}