
$heatheredgray: 		#B6A78E;
$conifer:				#b1d45d;
$greensmoke:			#a19f65;
$woodland:				#474729;

$primary:       $conifer;
$secondary:     $heatheredgray;
$tertiary:     	$greensmoke;

$primary-light		: 		lighten($primary, 25%);
$primary-dark		: 		darken($primary, 25%);

$secondary-light	: 		lighten($primary, 25%);
$secondary-dark		: 		darken($primary, 25%);

$tertiary-light		: 		lighten($primary, 15%);
$tertiary-dark		: 		darken($primary, 40%);


$body-bg:       white;



//== Social Colors

$facebook			:		#3b5998;
$facebook-dark		: 		darken($facebook, 25%);
$twitter			:		#1DA1F2;
$twitter-dark		: 		darken($twitter, 25%);
$google				:		#DB4437;
$google-dark		: 		darken($google, 25%);
$instagram			:		#262626;
$instagram-dark		: 		darken($instagram, 25%);
$linkedin			:		#0077B5;
$linkedin-dark		: 		darken($linkedin, 25%);
$pinterest			:		#BD081C;
$pinterest-dark		: 		darken($pinterest, 25%);
$youtube			:		#282828;
$youtube-dark		: 		darken($pinterest, 25%);

$social-btn-padding-y:         .2rem;
$social-btn-padding-x:         .5rem;
$social-btn-line-height:       .9rem;

